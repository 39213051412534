.team-section {
    background-color: white;
    padding: 30px;
    box-sizing: border-box;
}

.team-section .header {
    text-align: center;
    padding: 20px;
}

.team-section .header .title {
    font-size: 25px;
    font-weight: 100;
}

.team-section .card {
    text-align: center;
    background: rgba(255, 255, 255, 0.2) !important;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 20px;
}

.card-subtitle {
    height: 70px;
}

.team-section .card .profile {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 100%;
    overflow: hidden;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.team-section .card .profile img {
    width: 100%;
    height: 100%;
}

.team-section .card .card-footer {
    border: none;
    background-color: transparent;
}

.team-section .card .card-title h4 {
    font-size: 18px !important;
    font-weight: bold;
    padding-top: 23px;
    line-height: 0px;
    color: var(--blue);
}

.social_media {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 70%;
    margin: 0 auto;
    height: 30px;
    padding: 0 !important;
}

.social_media li a {
    padding: 8px;
    background: var(--green);
    color: #fff !important;
    display: flex;
    margin: 5px;
    box-sizing: border-box;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.5s;
}

.team-section .card:hover .social_media li a {
    background: var(--blue);

}

.social_media li a:hover {
    transform: translateY(-10px);
}

@media (max-width: 380px) {
    .team-section .card .profile {
        width: 100px;
        height: 100px;
    }
}

@media (min-width: 381px) and (max-width: 575px) {
    .team-section .card .profile {
        width: 100px;
        height: 100px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .team-section .card .profile {
        width: 100px;
        height: 100px;
    }
}

@media (min-width: 1200px) {
    .team-section .card .profile {
        width: 100px;
        height: 100px;
    }
}