.service_item {
  border: 1px solid #e8e8e8;
  margin-bottom: 20px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.service_item:nth-child(even) .img_section {
  background-color: var(--blue);
}

.service_item:nth-child(even) h6 {
  color: var(--blue);
  font-weight: 600;
}

.service_item:nth-child(odd) .img_section {
  background-color: var(--green);
}

.service_item:nth-child(odd) h6 {
  color: var(--green);
  font-weight: 600;
}

.service_item .img_section {
  width: 100%;
  height: 255px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.service_item .img_section img {
  width: 20% !important;
}

.service_item .img_section p {
  font-weight: 600;
  color: #fff;
  padding: 10px;
  width: 65%;
  text-align: center;
}

.service_item .service_text span,
.service_item .service_text_auto span {
  text-transform: capitalize;
  font-weight: 500;
  padding: 3px 0;
  cursor: pointer;
  transition: 0.5s;
}

.service_item .service_text span:hover,
.service_item .service_text_auto span:hover {
  color: var(--blue);
}

.service_item .service_text {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 15px 10px;
}

.service_item .service_text_auto {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 200px;
  padding: 15px 10px;
  height: auto;
}




/* ************** */
@media (max-width: 1199px) {
  .service_item .service_text {
    height: auto;
  }

  .service_item .img_section {
    height: 100%;
  }
}

@media (max-width: 768px) {
  .service_item .service_text {
    height: auto;
    padding: 20px;
  }

  .service_item .img_section {
    height: 100%;
    padding: 20px;
  }
}

/* ************** */