.about__section {
  width: 100%;
  height: auto;
  padding: 20px;
}

.about__section .logo_about {
  width: 100%;
  height: 350px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about__section .logo_about img {
  width: 100%;
  /* height: 100%; */
}

.about__section .about_text {
  padding: 30px;
}

.about__section .about_text h3 {
  font-weight: 600;
}

.about__section .about_text ul {
  padding-left: 40px;
}

.about__section .about_text ul li {
  margin-bottom: 10px;
}

/* Second */
.about__tabs {
  width: 100%;
  height: auto;
  padding: 20px;
  margin: 20px 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.about__tabs .tab__text {
  padding: 20px;
}

.about__tabs .tab__text h4 {
  font-weight: 600;
  color: var(--blue);
}

.about__tabs .tab__text ul {
  margin-left: 10px;
}

.about__tabs .tab__text ul li {
  margin-bottom: 20px;
}

.about__tabs .ant-tabs>.ant-tabs-nav,
.about__tabs .ant-tabs>div>.ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  width: 80%;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

/* **************************************** */

@media (max-width: 1199px) {
  .about__section .logo_about {
    height: 250px;
  }
}

@media (max-width: 991px) {
  .about__section .logo_about {
    height: 200px;
  }
}

@media (max-width: 768px) {
  .about__section .logo_about {
    width: 60%;
    height: 300px;
    margin: 0 auto;
  }
}

@media (max-width: 576px) {
  .about__section .logo_about {
    width: 80%;
    height: 300px;
  }
}

/* **************************** */