/* Top Secction  */

.top__section {
  width: 100%;
  height: 500px;
  background: url("./../imgs/33.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin-top: 5px;
}

.top__section .cover {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding-left: 50px;
  box-sizing: border-box;
}

.top__section .cover .text__section {
  padding: 90px 30px;
}

.top__section .cover .text__section h1 {
  color: #fff !important;
  /* color: var(--green); */
  font-weight: 600;
  text-transform: capitalize;
  font-size: 50px !important;
}

.top__section .cover .text__section h3 {
  color: #fff;
  /* color: var(--blue); */
  font-weight: 600;
  text-transform: capitalize;
  font-size: 30px;
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  white-space: nowrap;
  /* Keeps the content on a single line */
  letter-spacing: 1px;
  /* Adjust as needed */
  /* animation: typing 1s infinite; */
}

/* The typing effect */
@keyframes typing {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.top__section .cover .text__section .guess {
  padding: 10px 20px;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 600;
  font-size: 12px !important;
  letter-spacing: 1px;
  line-height: 70px;
  transition: ease-in-out, 0.5s;
}

.top__section .cover .text__section .guess:hover {
  background-color: #fff;
  color: #000;
}

/* **************************************** */
@media (max-width: 576px) {
  .top__section {
    height: 400px;
  }

  .top__section .cover .text__section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .top__section .cover .text__section h1 {
    color: var(--blue);
  }

  .top__section .cover .text__section h3 {
    font-size: 20px;
  }

  .mobile_display_block {
    display: block !important;
  }

  .top__section .cover .text__section .guess {
    line-height: 20px;
  }
}

/* **************************** */

/* Happy Client */
.happy__client {
  width: 100%;
  height: 150px;
  border: 1px solid red;
}

/* __services */
.__services {
  width: 100%;
  height: auto;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: rgba(0, 0, 0, 0.03); */
}

.__services .heading {
  width: 50%;
  height: auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.__services .heading h1 {
  font-weight: 500;
  color: var(--blue);
}

.__services .heading p {
  text-align: center;
  color: #7e7e7e;
  font-weight: 400;
}

.__services .serives_sub {
  width: 90%;
  padding: 10px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.__services .serives_sub .serives_sub_item {
  width: 20%;
  height: 150px;
  margin: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  background: var(--green);
  position: relative;
  overflow: hidden;
}

.__services .serives_sub .serives_sub_item:after {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 0%;
  height: 10px;
  background: var(--blue);
  left: 0px;
  transition: linear, 0.5s;
}

.__services .serives_sub .serives_sub_item:hover:after {
  width: 100%;
}

.__services .serives_sub .serives_sub_item:hover {
  background: var(--green);
}

.__services .serives_sub .serives_sub_item img {
  width: 40%;
  margin: 10px;
}

.__services .serives_sub .serives_sub_item h5 {
  font-size: 11px;
  color: #fff;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
}

.__services .serives_sub .expend {
  background: var(--green) !important;
}

/* **************************************** */

@media (max-width: 1199px) {
  .__services .heading {
    width: 100%;
  }

  .__services .serives_sub {
    width: 100%;
    flex-wrap: wrap;
  }

  .__services .serives_sub .serives_sub_item {
    margin-bottom: 30px;
  }

  .__services .serives_sub .serives_sub_item img {
    width: 60%;
  }

  .__services .serives_sub .serives_sub_item h5 {
    font-size: 10px;
    line-height: 10px;
  }
}

@media (max-width: 576px) {
  .__services .heading {
    width: 100%;
  }

  .__services .serives_sub {
    width: 100%;
    flex-wrap: wrap;
  }

  .__services .serives_sub .serives_sub_item {
    margin-bottom: 30px;
    width: 25%;
  }

  .__services .serives_sub .serives_sub_item img {
    width: 100%;
  }

  .__services .serives_sub .serives_sub_item h5 {
    font-size: 10px;
    line-height: 10px;
  }
}

/* **************************** */

/* Technology */
.technology__section {
  width: 100%;
  height: auto;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.technology__section .heading h1 {
  font-weight: 500;
  color: var(--blue);
}

.technology__section .heading p {
  text-align: center;
  color: #7e7e7e;
  font-weight: 400;
}

.technology__section .heading {
  width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.technology__section .card {
  transition: ease-in, 0.5s;
  margin-bottom: 30px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 6px 6px 12px #cccfd4, -6px -6px 12px #f0f3f8;
}

.technology__section .card .card-body span {
  font-size: 40px;
  padding: 10px;
  background: var(--greenlight);
  margin: 20px;
  color: #fff;
  transition: ease-in, 0.5s;
  border-radius: 10px;
}

.technology__section .card .card-body h4 {
  font-weight: 600;
  color: var(--green);
  font-size: 14px;
  transition: ease-in, 0.5s;
}

.technology__section .card .card-body p {
  font-weight: 600;
  transition: ease-in, 0.5s;
  color: #7e7e7e;
  font-size: 12px;
}

.technology__section .card .card-body a {
  color: var(--green);
}

.technology__section .card:hover {
  background: var(--darkblue);
}

.technology__section .card:hover .card-body span {
  background: #fff;
  color: var(--green);
}

.technology__section .card:hover .card-body p,
a {
  color: #fff;
}

/* **************************************** */

@media (max-width: 768px) {
  .technology__section .heading {
    width: 100%;
  }
}

/* **************************** */

/* ************************************************************* */

/* Chair man */
.chairmen__section {
  /* padding: 40px; */
  box-sizing: border-box;
  width: 100%;
  height: auto;
  border: 1px solid #e8e8e8;
  margin-bottom: 20px;
}

.chairmen__section .chairmen__img {
  border-radius: 100%;
  width: 60%;
  margin: 0 auto;
  height: 320px;
  background: url("./../imgs/dr.jpg");
  background-position: left;
  background-size: cover;
  box-shadow: rgba(28, 46, 109, 0.45) 0px 25px 20px -20px;
}

.chairmen__section .chairmen__text {
  padding-top: 30px;
}

.chairmen__section .chairmen__text h4,
h5 {
  font-weight: 600;
  text-transform: uppercase;
  color: var(--blue);
}

.chairmen__section .chairmen__text h5 {
  color: var(--green);
  font-weight: 600;
  text-transform: uppercase;
}

.chairmen__section .chairmen__text p {
  font-weight: 400;
  line-height: 25px;
}

/* **************************************** */

@media (max-width: 1199px) {
  .chairmen__section .chairmen__img {
    width: 80%;
    height: 290px;
    /* display: none; */
  }
}

@media (max-width: 991px) {
  .chairmen__section .chairmen__img {
    width: 80%;
    height: 290px;
    /* display: none; */
  }
}

@media (max-width: 768px) {
  .chairmen__section .chairmen__img {
    width: 75%;
    height: 320px;
  }
}

@media (max-width: 576px) {
  .chairmen__section .chairmen__img {
    width: 100%;
    height: 290px;
  }
}

/* **************************** */

/* PRODUCT SECTION */
.pro__section {
  width: 100%;
  height: auto;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.pro__section h1 {
  color: var(--blue);
  margin-bottom: 20px;
}

.pro__section .card {
  margin-bottom: 20px;
  border: 0px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 6px 6px 12px #cccfd4, -6px -6px 12px #f0f3f8;
}

.pro__section .card-body {
  transition: ease-in, 0.5s;
  background-color: var(--blue);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.pro__section .card-body p {
  text-align: justify;
  color: #fff;
}

.pro__section .card-body .card-title {
  color: var(--green);
  font-weight: bold;
  font-size: 14px !important;
}

.pro__section .card-img-top {
  filter: drop-shadow(3px 3px 3px #707070);
}

/* *** productSection *** */
.productSection {
  width: 100%;
  height: auto;
  padding: 30px;
  box-sizing: border-box;
  text-align: center;
  background: rgba(0, 0, 0, 0.03);
}

.productSection h1 {
  color: var(--blue);
  margin: 20px 0px;
}

.productSection .card {
  border-radius: 20px;
  background: #fff;
  margin-bottom: 30px;
  overflow: hidden;
  box-shadow: 6px 6px 12px #cccfd4, -6px -6px 12px #f0f3f8;
}

.productSection .card .card-img-top {
  width: 70% !important;
  margin: 0 auto;
  padding: 5px;
}

.productSection .card .card-title {
  font-size: 12px;
  font-weight: bold;
  color: #000;
}

.card {
  overflow: hidden;
}

.card .card-body:after {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 0%;
  height: 10px;
  background: var(--green);
  left: 0px;
  transition: linear, 0.5s;
}

.card:hover .card-body:after {
  width: 100%;
}

.hidden_product:after {
  content: "Coming Soon";
  position: absolute;
  bottom: -2px;
  width: 100%;
  height: 100%;
  background: rgba(41, 172, 71, 0.8);
  left: 0px;
  transition: linear, 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
}