.contact__section {
  padding: 20px;
  margin-top: 10px;
}

.contact__section .form__section {
  padding: 20px;
}
.contact__section .form__section h4 {
  font-weight: 600;
  padding: 10px;
}
.contact__section .img_sec {
  width: 80%;
  height: auto;
  margin: 20px auto;
  padding: 10px;
}

/* socend */
.contact_second {
  width: 100%;
  height: auto;
  padding: 30px;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  margin-bottom: 10px;
}
.contact_second .contact_item {
  text-align: center;
  transition: linear 0.2s;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid rgb(228, 224, 224);
}
.contact_second .contact_item span {
  padding: 10px;
  font-size: 25px;
  color: #fff;
  background-color: var(--green);
  margin-bottom: 20px;
}
.contact_second .contact_item h5 {
  font-size: 18px;
  font-weight: 600;
  color: var(--blue);
}
.contact_second .contact_item:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: var(--blue);
  color: #fff;
}
.contact_second .contact_item:hover h5 {
  color: var(--green);
}

.contact_second .contact_item:hover h5 {
  color: var(--green);
}
