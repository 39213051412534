.main_card {
    width: 100%;
    height: 400px;
    display: flex;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 20px;
}

.main_card .main_card_left {
    width: 65%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
}

.main_card .main_card_left .img_div {
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.main_card .main_card_left .img_div img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
}

.circle-1 {
    position: absolute;
    box-sizing: border-box;
    width: 160px;
    height: 160px;
    top: -5px;
    left: -5px;
    border-width: 2px;
    border-style: solid;
    border-color: var(--blue) var(--blue) var(--blue) transparent;
    border-radius: 50%;
    transition: all 1.5s ease-in-out;
}

.circle-2 {
    position: absolute;
    box-sizing: border-box;
    width: 170px;
    height: 170px;
    top: -10px;
    left: -10px;
    border-width: 2px;
    border-style: solid;
    transition: all 1.5s ease-in-out;
    border-radius: 50%;
    border-color: var(--blue) transparent var(--blue) var(--blue);
}

.main_card:hover .circle-2 {
    transform: rotate(360deg);
}

.main_card:hover .circle-1 {
    transform: rotate(-360deg);
}

.main_card .main_card_left h4 {
    font-weight: 800;
    font-size: 16px;
    letter-spacing: 1px;
    margin-top: 20px;
    margin-bottom: 0px;
}


.main_card .main_card_right {
    width: 34.5%;
    height: 100%;
    padding: 0px 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.main_card .main_card_right .right_item {
    width: 100%;
    height: 32%;
    background: rgba(41, 172, 71, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(0.8px);
    -webkit-backdrop-filter: blur(0.8px);
    border: 1px solid rgba(255, 255, 255, 0.64);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.main_card .main_card_right .right_item h3 {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
    color: transparent;
    background: #000;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    text-shadow: 0px 3px 3px rgba(255, 255, 255, 0.5);

}

.main_card .main_card_right .right_item h6 {
    font-size: 20px;
    font-weight: bold;
    color: transparent;
    background: #000;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    text-shadow: 0px 3px 3px rgba(255, 255, 255, 0.5);

}

.item_list {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    max-height: 300px;
}

.item_list h4 {
    font-size: 18px;
    font-weight: 900;
    color: var(--green);
}

.list-group {
    border-radius: 0px !important;
    background: rgba(255, 255, 255, 0.2);
}

.list-group-item {
    position: relative;
    display: block;
    margin-top: 10px;
    padding: 0.5rem 1rem;
    color: #212529;
    text-decoration: none;
    background-color: #fff;
    border: none !important;
    background: rgba(255, 255, 255, 0.71);
    border-radius: 10px !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.3px);
    -webkit-backdrop-filter: blur(6.3px);
    border: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: background linear, 0.5s;
    font-weight: bold;
    color: var(--blue) !important;
    font-size: 12px;
}


.list-group-item:hover {
    background: var(--green);
    color: #fff !important;
}

.list-group .li_active {
    background: var(--green);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px, rgba(0, 0, 0, 0.1) 0px 2px 2px, rgba(0, 0, 0, 0.1) 0px 4px 4px, rgba(0, 0, 0, 0.1) 0px 8px 8px, rgba(0, 0, 0, 0.1) 0px 16px 16px;
    color: #fff !important;
}

.react-datepicker__time-list-item--disabled {
    display: block !important;
}



.item_list2 .react-datepicker__input-container input,
.item_list2 select {
    text-align: center;
    width: 100%;
    position: relative;
    display: block;
    margin-top: 10px;
    padding: 0.5rem 1rem;
    background-color: #fff;
    border: none !important;
    background: rgba(255, 255, 255, 0.71);
    border-radius: 10px !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.3px);
    -webkit-backdrop-filter: blur(6.3px);
    border: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: background linear, 0.5s;
    font-weight: bold;
    color: var(--blue) !important;
    font-size: 12px !important;
}

.item_list2 .react-datepicker__input-container .react-datepicker-ignore-onclickoutside {
    border: 0px !important;
    border-radius: 10px !important;
}

.item_list2 .ant-btn-success {

    text-align: center;
    width: 100%;
    position: relative;
    display: block;
    margin-top: 10px;
    background: rgba(41, 172, 71, 0.5);
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(35, 148, 17, 0.3);
    cursor: pointer;
    transition: background linear, 0.5s;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
    color: var(--blue);
}

.item_list2 .ant-btn-success:hover {
    background-color: #fff;
    color: var(--green) !important;
    border: 0px;
}