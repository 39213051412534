.map_div {
    width: 100%;
    height: 400px;
    border-radius: 10px;
    background: rgba(28, 120, 23, 0.4);
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
    backdrop-filter: blur(17.8px);
    -webkit-backdrop-filter: blur(17.8px);
    overflow: hidden;
}

.--mart {
    width: 80%;
    height: auto;
    min-height: 100px;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    backdrop-filter: blur(95px);
    -webkit-backdrop-filter: blur(95px);
    border-top: 2px solid var(--green);
    border-bottom: 2px solid var(--green);
}

.--mart-active {
    background: rgba(0, 0, 0, 0.04);
}

.--mart .ant-btn-dashed {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    backdrop-filter: blur(95px);
    -webkit-backdrop-filter: blur(95px);
    margin-top: 5px !important;
}

.--mart h5 {
    font-size: 12px;
    font-weight: bold;
}

.--mart p {
    font-size: 10px;
}

.--mart h6 {
    font-size: 12px;
}

.pin_location .input-group-text {
    display: flex;
    align-items: center;
    padding: 10px !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 0 !important;
    color: var(--blue) !important;
    text-align: center;
    background-color: #fff !important;
    border: 0px !important;
    border-radius: 100% !important;
    box-shadow: 0 4px 50px rgb(0 0 0 / 20%) !important;

}

.pin_location input {
    width: 100%;
    position: relative;
    display: block;
    padding: 0.5rem 1.2rem;
    background-color: #fff;
    border: none !important;
    background: hsla(0, 0%, 100%, .71);
    border-radius: 10px !important;
    box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
    backdrop-filter: blur(6.3px);
    -webkit-backdrop-filter: blur(6.3px);
    border: 1px solid hsla(0, 0%, 100%, .5);
    cursor: pointer;
    transition: background linear, .5s;
    font-weight: 700;
    color: var(--blue) !important;
    font-size: 12px !important;
}

.pin_location input:focus {
    box-shadow: 0 4px 50px rgb(0 0 0 / 20%) !important;

}

@media (max-width: 768px) {
    .--mart {
        margin: 20px auto;
    }
}



/* payment section */

.payment_div {
    width: 70%;
    margin: 0 auto;
    padding: 30px 20px 10px 20px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin-top: 20px;
}

.payment_div input,
.paymentinput {
    text-align: center;
    position: relative;
    display: block;
    margin-top: 10px;
    padding: 0.5rem 1.2rem;
    background-color: #fff;
    border: none !important;
    background: hsla(0, 0%, 100%, .71);
    border-radius: 10px !important;
    box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
    backdrop-filter: blur(6.3px);
    -webkit-backdrop-filter: blur(6.3px);
    border: 1px solid hsla(0, 0%, 100%, .5);
    cursor: pointer;
    transition: background linear, .5s;
    font-weight: 700;
    color: var(--blue) !important;
    font-size: 12px !important;
}