.customer_dashboard {
  background: rgba(41, 172, 71, 0.05);
  margin: 20px;
  min-height: 350px;
  height: auto;
}

.customer_dashboard .dashboard_body {
  padding: 20px;
  height: auto;
}

.customer_dashboard .dashboard_body .widges_design {
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 3px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.customer_dashboard .dashboard_body .widges_design .card {
  outline: none;
  border-radius: 10px;
  border: none;
  padding: 15px 0px;
  /* background: transparent; */
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 10px 0px;
  border: 2px solid rgba(0, 0, 0, 0.125);
  transition: background, 0.5s;
  margin-bottom: 15px;
}

.customer_dashboard .dashboard_body .widges_design .card:hover {
  background: var(--green);
  /* color: #fff; */
}

.customer_dashboard .dashboard_body .widges_design .card .card-body {
  text-align: center;
}

.customer_dashboard .dashboard_body .widges_design .card .card-body .h5 {
  font-weight: bold !important;
  font-size: 16px !important;
}

.customer_dashboard .dashboard_body .widges_design .card .card-body .card-text {
  font-weight: bold;
  font-size: 16px;
}

/* MART ORDER */
.ant-table-thead>tr>th {
  position: relative;
  color: #fff !important;
  font-weight: bold !important;
  text-align: left;
  background: var(--green) !important;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}

.ant-table-wrapper {
  clear: both;
  max-width: 100%;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 2px solid rgba(0, 0, 0, 0.05);
  overflow-x: auto;
}

.table_section h4 {
  color: #000;
  font-weight: 500;
}

.table_section .detail-section {
  clear: both;
  max-width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 2px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
}

.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.ant-list-item-meta-content {
  flex: 1 0;
  width: 0;
  padding: 10px 20px;
  color: rgba(0, 0, 0, 0.85);

}

/* LINK */
.customer_dashboard .dashboard_body .nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.customer_dashboard .dashboard_body .nav-tabs {
  margin-bottom: 20px;
}

.customer_dashboard .dashboard_body .nav-tabs .nav-link.active,
.nav-tabs .show>.nav-link {
  color: #fff;
  background-color: var(--green) !important;
}

.customer_dashboard .dashboard_body .nav-link span {
  padding: 5px;
  line-height: 10px;
  margin-top: -10px !important;
  font-size: 16px;
}

/* become a Parner css */
.top_div {
  padding: 40px;
  box-sizing: border-box;
  background: rgba(41, 172, 71, 0.05);
  margin-top: 10px;
}

.place-order input {
  /* text-align: center; */
  width: 100%;
  position: relative;
  display: block;
  margin-top: 10px;
  padding: 0.5rem 1.2rem;
  background-color: #fff;
  border: none !important;
  background: hsla(0, 0%, 100%, .71);
  border-radius: 10px !important;
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  backdrop-filter: blur(6.3px);
  -webkit-backdrop-filter: blur(6.3px);
  border: 1px solid hsla(0, 0%, 100%, .5);
  cursor: pointer;
  transition: background linear, .5s;
  font-weight: 700;
  color: var(--blue) !important;
  font-size: 12px !important;
}

.place-order input:focus {

  box-shadow: 0 4px 30px rgb(0 0 0 / 10%) !important;

}


.form_main_section {
  width: 100%;
  height: auto;
  padding: 20px !important;
  box-sizing: border-box;
  border-radius: 10px;
  background: rgba(41, 172, 71, 0.5);
  backdrop-filter: blur(9.6px);
  -webkit-backdrop-filter: blur(9.6px);
  border: 1px solid rgba(34, 60, 149, 0.19);
}


.form_section {
  width: 80%;
  margin: 0 auto;
  height: auto;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
  backdrop-filter: blur(16.8px);
  -webkit-backdrop-filter: blur(16.8px);
  padding: 20px;
}

.form_section .form-control,
.form_section select {
  border-color: var(--blue) !important;
}

.form_section .form-control:focus {
  border-color: var(--blue) !important;
}

.form_section h4 {
  text-align: center;
  text-transform: capitalize;
  color: var(--blue);
  font-weight: bold;
  font-size: 18px;
}

.form_section .btn-primary {
  background-color: var(--green) !important;
  border: 0px;
  outline: 0;
}

.form_section .btn-primary:focus {
  box-shadow: none !important;
}

.form_section .logo {
  width: 40%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.form_section .logo img {
  width: 100%;
}

.form_section form {
  width: 70%;
  margin: 20px auto;
}

.form_section form .input-group-text {
  background-color: var(--blue);
  color: #fff;
}

.form_section form .input-group-text .outline-primary {
  background-color: var(--blue) !important;
}

.form_section .linkButton {
  font-size: 12px;
  padding: 20px 50px;
  color: var(--blue);
  font-weight: bold;
  transition: linear, 0.5s;
  cursor: pointer;
}


/* Customer review */
.dashboard_body .ant-rate {
  border: 1px solid red;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
  backdrop-filter: blur(17.3px);
  -webkit-backdrop-filter: blur(17.3px);
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
}

.ant-rate-star {
  color: var(--green) !important;
}