* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif !important;
}

.wrap {
  overflow: hidden !important;
  /* border: 1px solid red; */
}

h1 {
  font-size: 28px !important;
  font-weight: bold !important;
  padding: 20px;
}

.bg-gray {
  background: #f1eeee6e;
}

.row_margin {
  margin-top: 30px;
  margin-bottom: 30px;
}

:root {
  --green: #29ac47;
  --blue: #223c95;
  --greenlight: #29ac47b8;
  --darkblue: #1c2e6d;
}


.ant-message {
  z-index: 10000 !important;
  margin-top: 40px !important;
}

.btn-outline-primary {
  color: #fff !important;
  background-color: var(--blue) !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  border-color: var(--blue) !important;
}

.ant-btn-primary {
  border-color: var(--blue) !important;
  color: #fff !important;
  background-color: var(--blue) !important;
  border-radius: 5px !important;
  margin: 10px 0 !important;
}

.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  font-size: 12px !important;
  background: #fff;
  border-radius: 2px;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
  position: relative;
  padding: 10px 0px 10px 16px !important;
  overflow-wrap: break-word;
}


.location_main_div {
  position: relative;
  z-index: 100;
}

.autocomplete-dropdown-container {
  color: '#000';
  font-size: 12px;
  position: absolute;
  margin-top: -10px;
}

.location_span_parent {
  padding: 2px 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-sizing: border-box;
}

.location_span_parent:hover {
  background-color: var(--blue) !important;
}

.location_span_parent:hover .location_span_text {
  color: #fff !important;
}

.location_span_text {
  margin: 0px !important;
  line-height: 5px !important;
  text-align: center !important;
  color: var(--green) !important;
  font-weight: bold;
  font-size: 12px;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  font-size: 12px !important;
  text-transform: capitalize;
}

.thank-heading {
  font-weight: bold;
  color: var(--green);
}

.thank-text {
  font-weight: bold;
  padding: 0 10px 0 15px !important;
}


/* .ant-spin {
  color: #29ac47 !important;
} */
/* Fonts  */
@import url("./fonts.css");
/* Pages Css */
@import url("./header.css");
@import url("./home.css");
@import url("./ourproduct.css");
@import url("./about.css");
@import url("./services.css");
@import url("./contact.css");
@import url("./dashboard.css");
@import url("./martsection.css");
@import url("./marker.css");
@import url("./barbeProfile.css");
@import url("./team.css");
@import url("./footer.css");