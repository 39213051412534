/* .pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  position: absolute;
  transform: rotate(0deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
} */
.pin .fa-store {
  transform: rotate(45deg);
  color: var(--blue);
  font-size: 20px !important;
}

.bounce img {
  transform: rotate(45deg);
  margin-top: -10px;
  margin-left: 2px;
}

.overlay3 {
  min-width: 150px;
  height: auto;
  background-color: #fff;
  position: absolute;
  border-radius: 8px;
  overflow: hidden;
  top: -100px;
  left: 0px;
  transform: rotate(45deg);
  transition: 0.5s;
}

.overlay3 .title {
  width: 100%;
  padding: 6px 5px;
  background: var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.overlay3 .title .fa-store {
  color: #fff !important;
  transform: rotate(0deg);
  font-size: 15px !important;
}



.overlay3 .title h4 {
  color: #fff;
  margin-top: 5px;
  text-align: center;
}

.overlay3:hover {
  box-shadow: 0px 0px 5px 0px var(--blue), 0px 0px 5px 0px var(--blue);
}

.overlay3 h4 {
  display: block;
  line-height: 12px;
  font-size: 13px !important;
}

.overlay3 p {
  line-height: 14px;
  color: #000;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
  transform: translateY(-2000px) rotate(-45deg);
}

.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -25px 0 0 -20px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 3px brown;
  animation-delay: 1.1s;
}

.button a {
  background-color: var(--blue);
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: rotate(-45deg);
    opacity: 0;
  }

  60% {
    opacity: 0.5;
    transform: rotate(-45deg);
  }

  80% {
    opacity: 0.8;
    transform: rotate(-45deg);
  }

  100% {
    opacity: 1;
    transform: rotate(-45deg);
  }
}


.pulse {
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  z-index: -2;
}


.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -27px 0 0 0px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 3px var(--green);
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}