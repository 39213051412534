.page_header {
  width: 100%;
  height: 150px;
  background: var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.page_header h3 {
  font-weight: 600;
  color: #fff;
  letter-spacing: 1px;
}
.ourproduct__section .ptags {
  font-size: 12px;
  text-transform: none;
  text-align: center;
  width: 70%;
  margin: 0 auto;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 20px;
  padding: 20px;
}
.ourproduct__section .ptags span {
  color: var(--green);
}

.ourproduct__section .product {
  margin-bottom: 20px;
}
.ourproduct__section .card .card-header {
  background-color: var(--blue) !important;
  color: #fff !important;
}
.ourproduct__section .card {
  transition: linear, 0.5s;
}
.ourproduct__section .card .card-img,
.card-img-top {
  width: 80% !important;
  margin: 10px auto;
}
.ourproduct__section .card:hover {
  transform: scale(1.05);
}

/* Product Details */
.page_detail {
  padding: 40px;
}
.page_detail .row {
  margin-bottom: 40px;
}
.page_detail .p_details {
  padding: 10px 20px 10px 20px;
  line-height: 25px;
}
.page_detail .p_details li {
  margin-bottom: 10px;
}

/* ********************************* */
/* ************** */

@media (max-width: 768px) {
  .ourproduct__section .ptags {
    width: 100%;
  }
}
/* ************** */
