.header__section {
  overflow: hidden;
  padding: 0px !important;
}

.navbar {
  padding: 5px !important;
}

.navbar-light .navbar-nav .nav-link a {
  /* color: rgba(0, 0, 0, 0.5); */
  color: var(--green);
  z-index: 100px !important;
  padding: 10px;
}

.navbar-light .navbar-nav .nav-link {
  text-transform: capitalize;
  font-weight: 600;
  position: relative;
  font-size: 12px !important;
  transition: linear, 0.5s;
  /* padding: 10px !important; */
  margin-left: 20px !important;
  margin-bottom: auto !important;
  transition: background all, 0.5s;
  color: var(--green) !important;
  overflow: hidden;
}

.navbar-light .navbar-nav .nav-link:hover {
  background: var(--green);
  color: #fff !important;
}

.navbar-light .navbar-nav .nav-link:hover a {
  color: #fff;
}

.mobile_display_block {
  display: none !important;
  position: fixed;
  background: var(--blue) !important;
  color: #fff !important;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
}

.offcanvas-body ul {
  list-style: none;
  padding: 0px;
}

.offcanvas-body ul li {
  padding: 5px 20px;
  transition: linear, 0.5s;
}

.offcanvas-body ul li:hover {
  background-color: var(--blue);
}

.offcanvas-body ul li a {
  color: rgba(0, 0, 0, 0.5) !important;
}

.offcanvas-body ul li:hover a {
  color: #fff !important;
}

/* Modal CSS */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: var(--green) !important;
  opacity: 0.2 !important;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px !important;
  border-radius: 10px !important;
  outline: 0;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: none !important;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: none !important;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-body {
  overflow: hidden;
}

.modal-body .sigin_div {
  width: 80%;
  margin: 0 auto;
  height: auto;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
}

.modal-body .sigin_div .form__section {
  padding: 20px 20px 40px 20px;
  box-sizing: border;
  width: 100%;
}

.modal-body .sigin_div .form__section h4 {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  color: var(--blue);
}

.modal-body .sigin_div .form__section .btn-primary {
  color: #fff;
  background-color: var(--green) !important;
  border-color: var(--green) !important;
  margin-bottom: 10px;
}

.modal-body .sigin_div .form__section .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: var(--green);
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.modal-body .sigin_div .form__section span {
  color: var(--blue);
  font-weight: bold;
  font-size: 12px;
  transition: linear, 0.5s;
  cursor: pointer;
  margin: 0 auto !important;
}

.modal-body .sigin_div .form__section a:hover {
  color: var(--green);
}

@media (max-width: 768px) {
  .modile_display_none {
    display: none !important;
  }

  .mobile_display_block {
    display: block !important;
  }
}



/* button css  ***********************/

/* CSS */
.button-33 {
  box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset, rgba(44, 187, 99, .15) 0 1px 2px, rgba(44, 187, 99, .15) 0 2px 4px, rgba(44, 187, 99, .15) 0 4px 8px, rgba(44, 187, 99, .15) 0 8px 16px, rgba(44, 187, 99, .15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-33:hover {
  box-shadow: rgba(44, 187, 99, .35) 0 -25px 18px -14px inset, rgba(44, 187, 99, .25) 0 1px 2px, rgba(44, 187, 99, .25) 0 2px 4px, rgba(44, 187, 99, .25) 0 4px 8px, rgba(44, 187, 99, .25) 0 8px 16px, rgba(44, 187, 99, .25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}

/*  */
.animated-button {
  display: inline-block;
  -webkit-transform: scale(1);
  transform: scale(1);
  text-decoration: none;
  padding: 5px 30px !important;
  color: #fff;
  font-weight: bold;
  right: 0px;
  background: var(--blue);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
  margin-bottom: 10px;
}


@keyframes animateTop {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background: var(--blue);

  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1.1);
    background: var(--green);

  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


.sigin_div .input-group .form-control {
  border: 1px solid var(--green);
}