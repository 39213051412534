.footer {
  width: 100%;
  height: auto;
  padding: 30px 20px;
  background-color: var(--blue);
}

.footer .footer__about {
  padding: 10px;
}

.footer .footer__about img {
  width: 40%;
  margin-bottom: 20px;
}

.footer .footer__about p {
  color: #fff;
}

.footer .footer__about ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0px;
}

.footer .footer__about ul li {
  transition: linear, 0.5s;
}

.footer .footer__about ul li span {
  margin-left: 5px;
  transition: linear, 0.5s;
  padding: 10px;
  font-size: 15px;
  color: #1a2755;
  background-color: #fff;
  box-shadow: #1a2755 0px 1.95px 2px;
}

.footer .footer__about ul li:hover span {
  box-shadow: inset #1a2755 1.95px 1.95px 2.6px;
  color: #fff;
  background: #1a2755;
}

.footer .footer__product {
  padding: 10px;
}

.footer h4 {
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  color: var(--green);
}

.footer .footer__product ul {
  list-style: none;
  padding: 0px;
  margin-top: 10px;
}

.footer .footer__product ul li {
  margin-top: 10px;
  font-weight: 600;
  text-transform: capitalize;
  padding-left: 5px;
  position: relative;
  transition: ease-in, 0.5s;
}

.footer .footer__product ul li a {
  color: #e7f2f4;
}

.footer .footer__product ul li:before {
  content: " ";
  width: 8px;
  border-radius: 100%;
  height: 8px;
  position: absolute;
  left: 10px;
  top: 30%;
  background-color: #fff;
  transition: ease-in, 0.5s;
  opacity: 0;
}

.footer .footer__product ul li:hover:before {
  opacity: 1;
  left: -10px;
}

.footer .footer__product ul li:hover {
  padding-left: 10px;
}

/* __________________________ */
.footer .footer__usefull {
  padding: 10px;
}

.footer .footer__usefull h4 {
  font-weight: 600;
  font-size: 18px;
  /* color: #fff; */
  margin-top: 10px;
}

.footer .footer__usefull ul {
  list-style: none;
  padding: 0px;
  margin-top: 10px;
}

.footer .footer__usefull ul li {
  margin-top: 10px;
  font-weight: 600;
  text-transform: capitalize;
  padding-left: 5px;
  position: relative;
  transition: ease-in, 0.5s;
}

.footer .footer__usefull ul li a {
  color: #e7f2f4;
}

.footer .footer__usefull ul li:before {
  content: "";
  width: 8px;
  border-radius: 100%;
  height: 8px;
  position: absolute;
  left: 10px;
  top: 30%;
  background-color: #fff;
  transition: ease-in, 0.5s;
  opacity: 0;
}

.footer .footer__usefull ul li:hover:before {
  opacity: 1;
  left: -10px;
}

.footer .footer__usefull ul li:hover {
  padding-left: 10px;
}

/* _________________________ */
.footer .footer__address {
  padding: 10px;
}

.footer .footer__address h4 {
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  text-align: center;
}

.footer .footer__address p {
  font-size: 15px;
  color: #fff;
  text-align: center;
  margin-top: 10px;
}